.main-menu {
	background-color: #272322;
	color: white;
}

.menu-items {
	display: flex;
	justify-content: space-evenly;
}

.left-side {
	display: flex;
}

.left-side > li {
	padding: 1.6em 2em;
	list-style-type: none;
}

.left-side > li:hover {
	background-color: #161413;
}

.right-side {
	display: flex;
	align-items: center;
}